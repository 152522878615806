import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, Fragment as _Fragment } from "vue"

const _hoisted_1 = { class: "p-mt-3" }
const _hoisted_2 = { class: "p-grid" }
const _hoisted_3 = { class: "p-col p-fluid" }
const _hoisted_4 = { class: "p-col p-fluid" }
const _hoisted_5 = { class: "p-text-right p-d-inline" }
const _hoisted_6 = { class: "p-col-3 p-fluid" }
const _hoisted_7 = { class: "p-col p-fluid" }
const _hoisted_8 = { class: "p-col p-fluid" }
const _hoisted_9 = { class: "p-col p-fluid" }
const _hoisted_10 = { class: "p-fluid p-pt-3" }
const _hoisted_11 = { class: "p-col p-fluid" }
const _hoisted_12 = {
  key: 0,
  class: "p-col p-fluid"
}
const _hoisted_13 = { class: "p-shadow-1" }
const _hoisted_14 = { class: "p-grid p-mt-2 p-p-2" }
const _hoisted_15 = { class: "p-col" }
const _hoisted_16 = { class: "p-col" }
const _hoisted_17 = { class: "p-col" }
const _hoisted_18 = { class: "p-col" }
const _hoisted_19 = { class: "p-col" }
const _hoisted_20 = { class: "p-col" }
const _hoisted_21 = {
  key: 0,
  class: "p-col p-fluid"
}
const _hoisted_22 = {
  key: 1,
  class: "p-col p-d-flex"
}
const _hoisted_23 = { class: "p-text-right" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Calendar = _resolveComponent("Calendar")
  const _component_Dropdown = _resolveComponent("Dropdown")
  const _component_InputText = _resolveComponent("InputText")
  const _component_Checkbox = _resolveComponent("Checkbox")
  const _component_Button = _resolveComponent("Button")
  const _component_Panel = _resolveComponent("Panel")
  const _component_AutoComplete = _resolveComponent("AutoComplete")
  const _component_InputNumber = _resolveComponent("InputNumber")
  const _component_Column = _resolveComponent("Column")
  const _component_DataTable = _resolveComponent("DataTable")

  return (_openBlock(), _createBlock(_Fragment, null, [
    _createVNode("div", _hoisted_1, [
      _createVNode(_component_Panel, {
        header: "Céges adatok",
        toggleable: true
      }, {
        default: _withCtx(() => [
          _createVNode("div", _hoisted_2, [
            _createVNode("div", _hoisted_3, [
              _createVNode(_component_Calendar, {
                modelValue: _ctx.paperData.date,
                "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => (_ctx.paperData.date = $event)),
                dateFormat: "yy.mm.dd",
                showButtonBar: true,
                onDateSelect: _cache[2] || (_cache[2] = ($event: any) => (_ctx.testLog($event)))
              }, null, 8, ["modelValue"])
            ]),
            _createVNode("div", _hoisted_4, [
              _createVNode(_component_Dropdown, {
                modelValue: _ctx.paperData.company,
                "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => (_ctx.paperData.company = $event)),
                options: _ctx.companyList,
                optionLabel: "companyName",
                placeholder: "Cég kiválasztása",
                filter: true,
                onChange: _cache[4] || (_cache[4] = ($event: any) => (_ctx.onSelectCompany($event))),
                dataKey: "companyId"
              }, {
                option: _withCtx((slotProps) => [
                  _createTextVNode(_toDisplayString(slotProps.option.companyName) + "------ ", 1),
                  _createVNode("div", _hoisted_5, _toDisplayString(slotProps.option.cui), 1)
                ]),
                _: 1
              }, 8, ["modelValue", "options"])
            ]),
            _createVNode("div", _hoisted_6, [
              _createVNode(_component_InputText, {
                type: "text",
                modelValue: _ctx.paperData.docIns,
                "onUpdate:modelValue": _cache[5] || (_cache[5] = ($event: any) => (_ctx.paperData.docIns = $event)),
                placeholder: "Faktura"
              }, null, 8, ["modelValue"])
            ]),
            _createVNode("div", _hoisted_7, [
              _createVNode(_component_InputText, {
                type: "text",
                modelValue: _ctx.paperData.nrCar,
                "onUpdate:modelValue": _cache[6] || (_cache[6] = ($event: any) => (_ctx.paperData.nrCar = $event)),
                placeholder: "Rendszám"
              }, null, 8, ["modelValue"])
            ]),
            _createVNode("div", _hoisted_8, [
              _createVNode(_component_InputText, {
                type: "text",
                modelValue: _ctx.paperData.curier,
                "onUpdate:modelValue": _cache[7] || (_cache[7] = ($event: any) => (_ctx.paperData.curier = $event)),
                placeholder: "Beszállitó neve"
              }, null, 8, ["modelValue"])
            ]),
            _createVNode("div", _hoisted_9, [
              _createVNode(_component_InputText, {
                type: "text",
                modelValue: _ctx.paperData.pageId,
                "onUpdate:modelValue": _cache[8] || (_cache[8] = ($event: any) => (_ctx.paperData.pageId = $event)),
                placeholder: "Nr. doc",
                disabled: ""
              }, null, 8, ["modelValue"])
            ]),
            _createVNode("div", _hoisted_10, [
              _createVNode(_component_Checkbox, {
                modelValue: _ctx.paperData.checked,
                "onUpdate:modelValue": _cache[9] || (_cache[9] = ($event: any) => (_ctx.paperData.checked = $event)),
                binary: true
              }, null, 8, ["modelValue"])
            ]),
            _createVNode("div", _hoisted_11, [
              _createVNode(_component_Button, {
                label: "Mentés",
                icon: "pi pi-save",
                class: "p-button-success",
                onClick: _cache[10] || (_cache[10] = ($event: any) => (_ctx.savePage()))
              })
            ]),
            (_ctx.paperData.pageId != undefined)
              ? (_openBlock(), _createBlock("div", _hoisted_12, [
                  _createVNode(_component_Button, {
                    label: "Nyomtatás",
                    icon: "pi pi-save",
                    class: "p-button-success",
                    onClick: _cache[11] || (_cache[11] = ($event: any) => (_ctx.printPage()))
                  })
                ]))
              : _createCommentVNode("", true)
          ])
        ]),
        _: 1
      }),
      _createVNode("div", _hoisted_13, [
        _createVNode("div", _hoisted_14, [
          _createVNode("div", _hoisted_15, [
            _createVNode(_component_AutoComplete, {
              modelValue: _ctx.item.name,
              "onUpdate:modelValue": _cache[12] || (_cache[12] = ($event: any) => (_ctx.item.name = $event)),
              suggestions: _ctx.intelisenseItemList,
              onComplete: _cache[13] || (_cache[13] = ($event: any) => (_ctx.searchItem($event))),
              onItemSelect: _cache[14] || (_cache[14] = ($event: any) => (_ctx.intelItemSelected($event))),
              field: "name",
              placeholder: "Megnevezes"
            }, null, 8, ["modelValue", "suggestions"])
          ]),
          _createVNode("div", _hoisted_16, [
            _createVNode(_component_Dropdown, {
              modelValue: _ctx.item.um,
              "onUpdate:modelValue": _cache[15] || (_cache[15] = ($event: any) => (_ctx.item.um = $event)),
              options: _ctx.UmList,
              placeholder: "U/M"
            }, null, 8, ["modelValue", "options"])
          ]),
          _createVNode("div", _hoisted_17, [
            _createVNode(_component_InputNumber, {
              modelValue: _ctx.item.cantPrim,
              "onUpdate:modelValue": _cache[16] || (_cache[16] = ($event: any) => (_ctx.item.cantPrim = $event)),
              placeholder: "Cant. Prim",
              mode: "decimal",
              locale: "hu-HU",
              minFractionDigits: 2
            }, null, 8, ["modelValue"])
          ]),
          _createVNode("div", _hoisted_18, [
            _createVNode(_component_InputNumber, {
              modelValue: _ctx.item.AcPUfTVA,
              "onUpdate:modelValue": _cache[17] || (_cache[17] = ($event: any) => (_ctx.item.AcPUfTVA = $event)),
              placeholder: "P.U. fara TVA",
              mode: "decimal",
              locale: "hu-HU",
              minFractionDigits: 2
            }, null, 8, ["modelValue"])
          ]),
          _createVNode("div", _hoisted_19, [
            _createVNode(_component_InputNumber, {
              modelValue: _ctx.item.TVA,
              "onUpdate:modelValue": _cache[18] || (_cache[18] = ($event: any) => (_ctx.item.TVA = $event)),
              placeholder: "TVA",
              mode: "decimal",
              locale: "hu-HU",
              minFractionDigits: 2
            }, null, 8, ["modelValue"])
          ]),
          _createVNode("div", _hoisted_20, [
            _createVNode(_component_InputNumber, {
              modelValue: _ctx.item.VaPUcTVA,
              "onUpdate:modelValue": _cache[19] || (_cache[19] = ($event: any) => (_ctx.item.VaPUcTVA = $event)),
              placeholder: "Vanzare",
              mode: "decimal",
              locale: "hu-HU",
              minFractionDigits: 2
            }, null, 8, ["modelValue"])
          ]),
          (_ctx.item.id === null)
            ? (_openBlock(), _createBlock("div", _hoisted_21, [
                _createVNode(_component_Button, {
                  label: "Hozzáad",
                  icon: "pi pi-plus",
                  class: "p-mr-2",
                  onClick: _cache[20] || (_cache[20] = ($event: any) => (_ctx.addItem()))
                })
              ]))
            : (_openBlock(), _createBlock("div", _hoisted_22, [
                _createVNode(_component_Button, {
                  label: "Mentés",
                  icon: "pi pi-plus",
                  class: "p-mr-2",
                  onClick: _cache[21] || (_cache[21] = ($event: any) => (_ctx.addItem()))
                }),
                _createVNode(_component_Button, {
                  label: "Törlés",
                  icon: "pi pi-plus",
                  class: "p-mr-2 p-button-danger",
                  onClick: _cache[22] || (_cache[22] = ($event: any) => (_ctx.deleteItem()))
                })
              ]))
        ])
      ])
    ]),
    _createVNode(_component_DataTable, {
      value: [..._ctx.paperData.data.values()],
      class: "p-shadow-2 p-datatable-sm p-datatable-gridlines",
      dataKey: "TVA",
      onRowSelect: _ctx.onRowSelect,
      selectionMode: "single"
    }, {
      footer: _withCtx(() => [
        _createVNode("div", _hoisted_23, " Total: " + _toDisplayString(parseFloat(
            [..._ctx.paperData.data.values()].reduce(
              (x, y) => (y = y.VaVcTVA + x),
              0
            )
          ).toFixed(2)), 1)
      ]),
      default: _withCtx(() => [
        _createVNode(_component_Column, {
          field: "name",
          header: "Megnevezés"
        }),
        _createVNode(_component_Column, {
          field: "um",
          header: "U.M."
        }),
        _createVNode(_component_Column, {
          field: "cantPrim",
          header: "Cant. Prim"
        }),
        _createVNode(_component_Column, {
          field: "TVA",
          header: "TVA"
        }),
        _createVNode(_component_Column, {
          field: "AcPUfTVA",
          header: "Ach. P.U (fara TVA)"
        }),
        _createVNode(_component_Column, {
          field: "AcVfTVA",
          header: "Ach. Valoare (fara TVA)"
        }),
        _createVNode(_component_Column, {
          field: "adaos",
          header: "Adaos"
        }),
        _createVNode(_component_Column, {
          field: "AdPUfTVA",
          header: "Ada. P.U (fara TVA)"
        }),
        _createVNode(_component_Column, {
          field: "AdTfTVA",
          header: "Ada. Total (fara TVA)"
        }),
        _createVNode(_component_Column, {
          field: "VaPUfTVA",
          header: "Vanz. P.U (fara TVA)"
        }),
        _createVNode(_component_Column, {
          field: "VaVfTVA",
          header: "Vanz. Valoare (fara TVA)"
        }),
        _createVNode(_component_Column, {
          field: "VaPUcTVA",
          header: "Vanz. P.U (cu TVA)"
        }),
        _createVNode(_component_Column, {
          field: "VaVcTVA",
          header: "Vanz. Valoare (cu TVA)"
        })
      ]),
      _: 1
    }, 8, ["value", "onRowSelect"])
  ], 64))
}