import * as yup from 'yup';
import { date } from 'yup/lib/locale';
import {CompanyMinimal} from '@/models/CompanyMinimal'
import { Item } from './Item';

export type PaperDataT={
    pageId?:number
    date?:Date,
    company:CompanyMinimal|{}
    docIns:string,
    nrCar:string,
    curier:string,
    data:Map<number,Item>
    checked?:boolean
    summ:{tva:number,value:number}[]
    dateStrin?:string
    total?:number
}

export class PaperData implements PaperDataT{
    public date?: Date;
    company: {} | CompanyMinimal;
    docIns: string;
    nrCar: string;
    curier: string;
    pageId?: number | undefined;
    data:Map<number,Item>
    checked?:boolean;
    summ:{tva:number,value:number}[];

    constructor(){
        this.company = {};
        this.docIns = '',
        this.nrCar = '',
        this.curier = '',
        this.pageId = undefined
        this.data = new Map()
        this.checked = true
        this.summ = new Array()
    }
    
}