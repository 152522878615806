
import {
  defineComponent,
  onMounted,
  reactive,
  ref,
  Ref,
  readonly,
  watchEffect,
} from "vue";

import Panel from "primevue/panel";
import Dropdown from "primevue/dropdown";
import InputText from "primevue/inputtext";
import InputNumber from "primevue/inputnumber";
import Calendar from "primevue/calendar";
import AutoComplete from "primevue/autocomplete";
import Button from "primevue/button";
import DataTable from "primevue/datatable";
import Column from "primevue/column";
import Checkbox from "primevue/checkbox";

import { PaperData } from "@/models/PaperData";
import { CompanyMinimal } from "@/models/CompanyMinimal";
import {
  getCompanies,
  getItemsByCompany,
  getPageData,
  getPageDataUtil,
  savePageData,
} from "@/api";
import { calcItem, Item } from "@/models/Item";
import { UmList } from "@/models/UmLIst";

import { cloneDeep, extend } from "lodash";

import { useRouter, useRoute } from "vue-router";
import { number } from "yup/lib/locale";
const pdf = require("@/doc/PdfGen");

export default defineComponent({
  name: "Paper",
  components: {
    Panel,
    Dropdown,
    Calendar,
    InputText,
    AutoComplete,
    InputNumber,
    Button,
    DataTable,
    Column,
    Checkbox,
  },
  setup() {
    //VARIABLES
    const router = useRouter();
    const route = useRoute();
    const paperData: Ref<PaperData> = ref(new PaperData());
    const companyList: Ref<CompanyMinimal[]> = ref([]);
    const companyItemList: Ref<Item[]> = ref([]);
    const intelisenseItemList: Ref<Item[]> = ref([]);
    const item: Ref<Item> = ref(new Item());

    //MOUNT
    onMounted(async () => {
      companyList.value = await getCompanies();
      if (route.query.pageId != null) {
        let x = await getPageData(parseInt(route.query.pageId as string));
        console.log(x);
        const m = new Map<number, Item>();
        const data: Item[] = JSON.parse(x.data as any) as Item[];
        data.map((e, i) => {
          m.set(i, { ...e, id: i });
        });
        console.log(m);
        paperData.value = { ...x, data: m, date: new Date(x.date as Date) };
      }
    });

    //EVENTS
    const onSelectCompany = async (event: any) => {
      companyItemList.value = await getItemsByCompany(event.value.companyId);
      const r = await getPageDataUtil(event.value.companyId)
      paperData.value.nrCar = r.nrCar
      paperData.value.curier = r.curier
    };

    const searchItem = async (event: any) => {
      intelisenseItemList.value = await companyItemList.value.filter((x) => {
        return x.name
          ?.toUpperCase()
          .includes((event.query as string).toUpperCase());
      });
    };
    const intelItemSelected = async (event: { value: Item }) => {
      item.value = { ...event.value, id: null };
    };

    const addItem = async () => {
      if (item.value.id == null) {
        item.value.id =
          (Array.from(paperData.value.data?.keys())
            .sort((a, b) => {
              return a > b ? 1 : -1;
            })
            .pop() || 0) + 1;
      }
      calcItem(item.value);
      paperData.value.data.set(item.value.id, item.value);
      item.value = new Item();
    };
    const deleteItem = async () => {
      if (item.value.id !== null) paperData.value.data.delete(item.value.id);
      item.value = new Item();
    };

    const onRowSelect = async (event: { data: Item; index: number }) => {
      item.value = { ...event.data };
    };

    const savePage = async () => {
      try {
        const myDate = new Date(
          new Date(paperData.value.date as Date).toLocaleDateString()
        );
        myDate.setDate(myDate.getDate() + 1);
        await savePageData({
          ...paperData.value,
          data: [...paperData.value.data.values()],
          date: myDate,
        });
        paperData.value = new PaperData();
        item.value = new Item();
        router.push("/paper");
      } catch (err) {
        console.log(err);
      }
    };

    const testLog = (event: any) => {
      console.log(event);
    };

    const printPage = async () => {
      let x = await getPageData(paperData.value.pageId as number);
      pdf.genPdf(x);
    };

    return {
      paperData,
      companyList,
      item,
      companyItemList,
      intelisenseItemList,
      UmList,
      onSelectCompany,
      searchItem,
      intelItemSelected,
      addItem,
      onRowSelect,
      deleteItem,
      savePage,
      testLog,
      printPage,
    };
  },
});
