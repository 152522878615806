import * as yup from 'yup';
import { date } from 'yup/lib/locale';



export class Item {
    public id:number|null
    public itemId?: number
    public companyId?: number
    public name?: string
    public um?: string
    public TVA: number
    public cantPrim: number
    public AcPUfTVA: number
    public VaPUcTVA: number
    public AcVfTVA: number
    public adaos: number
    public AdPUfTVA: number
    public AdTfTVA: number
    public VaPUfTVA: number
    public VaVfTVA: number
    public VaVcTVA: number
    constructor() {
        this.id=null;
        this.TVA = 0;
        this.cantPrim = 0;
        this.AcPUfTVA = 0;
        this.VaPUcTVA = 0;
        this.AcVfTVA = 0;
        this.adaos = 0;
        this.AdPUfTVA = 0;
        this.AdTfTVA = 0;
        this.VaPUfTVA = 0;
        this.VaVfTVA = 0;
        this.VaVcTVA = 0;
    }
}

export const calcItem = (x:Item)=>{
        const x1 = parseFloat(((x.VaPUcTVA * 100) / (100 + x.TVA)).toFixed(2));
        const x2 = parseFloat((x1 - x.AcPUfTVA).toFixed(2));
        const x3 = parseFloat((x2 * x.cantPrim).toFixed(2));
        const x4 = parseFloat((x.AcPUfTVA * x.cantPrim).toFixed(2));
        x.cantPrim = parseFloat(x.cantPrim.toFixed(2))
        x.AcPUfTVA = parseFloat(x.AcPUfTVA.toFixed(2))
        x.AcVfTVA = parseFloat(x4.toFixed(2))
        x.TVA = parseFloat(x.TVA.toFixed(2))
        x.adaos = parseFloat(((x2 / x.AcPUfTVA) * 100).toFixed(2))
        x.AdPUfTVA = parseFloat(x2.toFixed(2))
        x.AdTfTVA = parseFloat((x2 * x.cantPrim).toFixed(2))
        x.VaPUfTVA = parseFloat((x.AcPUfTVA + x2).toFixed(2))
        x.VaVfTVA = parseFloat((x4 + x3).toFixed(2))
        x.VaPUcTVA = parseFloat(x.VaPUcTVA.toFixed(2))
        x.VaVcTVA = parseFloat((x.VaPUcTVA * x.cantPrim).toFixed(2))
}